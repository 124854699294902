<template>
  <div>
    <!--Top Page Image Viewer & Contact Methods-->
    <top-page />

    <!--Embroidery Thread Chart-->
    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
        >
          <v-card
            flat
            color="grey lighten-5"
          >
            <v-card-title
              primary
              class="headline font-weight-bold secondary--text"
            >
              Madeira
              Thread Chart
            </v-card-title>
            <v-card-text>
              <v-img src="./img/Madeira+Thread+Chart+2017-1.jpg" />
              <v-img src="./img/Madeira+Thread+Chart+2017-2.jpg" />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <!--PMS Chart-->
    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          d-flex
          xs12
        >
          <v-card
            flat
            color="grey lighten-5"
          >
            <v-card-title
              primary
              class="headline font-weight-bold secondary--text"
            >
              Pantone Color
              Chart
            </v-card-title>
            <v-card-text>
              <v-img src="./img/iDex+PMS+Color+Chart+2017.jpg" />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPage from '@/components/TopPage';

export default {
  name: 'Resources',
  components: {
    TopPage,
  },
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
  },
};
</script>

<style scoped>
  button {
    outline: none;
  }
</style>
